import React from 'react'
import { OutlinedTextFieldProps, TextField, Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import MaskedInput from 'react-text-mask'
import { RiskMinor } from '@shopify/polaris-icons'

const TextMaskCustom = React.forwardRef<HTMLInputElement, any>(function TextMaskCustom(
  { mask, ...props },
  ref,
) {
  return <MaskedInput {...props} mask={mask} guide={false} inputRef={ref} />
})

export interface BasicTextFieldProps<T>
  extends Omit<OutlinedTextFieldProps, 'name' | 'variant' | 'label'> {
  name: keyof T
  label?: string
  mask?: Array<string | RegExp>
}

export const BasicTextField = <T extends unknown>({
  name,
  type,
  label,
  css,
  mask,
  error,
  helperText,
  ...rest
}: BasicTextFieldProps<T>) => {
  return (
    <div css={css}>
      <Typography css={tw`mb-1`} variant="h6" color={colors.GRAY_COOL_GRAY}>
        {label}
      </Typography>
      <TextField
        name={name as string}
        type={type}
        variant="outlined"
        placeholder={label}
        error={error}
        helperText={
          !!error &&
          !!helperText && (
            <div css={tw`flex items-center gap-1`}>
              <RiskMinor width={20} fill={colors.COLORS_RED_500} />
              <Typography variant="body2" color={colors.COLORS_RED_500}>
                {helperText}
              </Typography>
            </div>
          )
        }
        FormHelperTextProps={{
          sx: { ml: 0 },
        }}
        {...rest}
        InputProps={
          mask
            ? {
                inputComponent: TextMaskCustom as any,
              }
            : undefined
        }
        inputProps={{
          mask,
        }}
      />
    </div>
  )
}
