import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { BasicTextField, BasicTextFieldProps } from '../BasicTextField'

export const FormikTextField = <T extends unknown>({
  name,
  label,
  ...rest
}: BasicTextFieldProps<T>) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    setFieldValue,
    validateField,
  } = useFormikContext<T>()

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]')

    if (el?.parentElement) {
      el?.parentElement?.scrollIntoView({ block: 'center' })

      return
    }

    el?.scrollIntoView()
  }, [isSubmitting])

  const onBlur = (e: any) => {
    const res = handleBlur(name as string)(e)

    setFieldValue(name as string, `${values[name]}`.trim())

    validateField(name as string)

    return res
  }

  const isTouched = touched[name] as boolean
  const error = errors[name] as string | undefined

  return (
    <BasicTextField
      name={name}
      value={values[name]}
      error={Boolean(isTouched && error)}
      helperText={isTouched && error}
      placeholder={label}
      label={label}
      onChange={handleChange(name)}
      onBlur={onBlur}
      {...rest}
    />
  )
}
