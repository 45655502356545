import { useCallback, useMemo, useState } from 'react'
import { DocusignSigningField } from 'src/interfaces/docusign'
import { DocusignFieldsFormModalInput } from './DocusignFieldsFormModalInput'
import {
  DocusignFieldTabLabel,
  docusignSectionsMap,
  docusignSectionsTitleMap,
  DocusignSectionTitle,
  TaxClassificationType,
} from 'src/constants/docusign'
import { useFormikContext } from 'formik'
import { DocusignFieldsFormModalValues } from './DocusignFieldsFormModal.interface'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { Button } from '../shared/Button'
import { CircleMinusMajor, CirclePlusMajor } from '@shopify/polaris-icons'

interface Props {
  fields: Array<DocusignSigningField>
}

export const DocusignFieldsFormModalInputList = ({ fields }: Props) => {
  const { values, setFieldValue } = useFormikContext<DocusignFieldsFormModalValues>()

  const [showAdditionalVehicleInformation1, setShowAdditionalVehicleInformation1] =
    useState<boolean>(false)

  const [showAdditionalVehicleInformation2, setShowAdditionalVehicleInformation2] =
    useState<boolean>(false)

  const orderedFields = useMemo<
    { sectionKey: DocusignSectionTitle; fields: DocusignSigningField[] }[]
  >(() => {
    const orderedFieldsRes: {
      sectionKey: DocusignSectionTitle
      fields: DocusignSigningField[]
    }[] = []

    Object.entries(docusignSectionsMap).forEach(([key, fieldItems]) => {
      const res: { sectionKey: DocusignSectionTitle; fields: DocusignSigningField[] } = {
        sectionKey: key as DocusignSectionTitle,
        fields: [],
      }

      const taxClassificationTypeValue = values[DocusignFieldTabLabel.taxClassificationType]

      fieldItems.forEach((fieldItem) => {
        const fieldToAdd = fields.find((field) => field.tabLabel === fieldItem)

        if (
          fieldItem === DocusignFieldTabLabel.llcType &&
          taxClassificationTypeValue !== TaxClassificationType.Llc
        ) {
          return
        }

        if (
          fieldItem === DocusignFieldTabLabel.otherTaxClassification &&
          taxClassificationTypeValue !== TaxClassificationType.Other
        ) {
          return
        }

        if (fieldToAdd) {
          res.fields.push(fieldToAdd)
        }
      })

      if (res.fields.length) {
        orderedFieldsRes.push(res)
      }
    })

    return orderedFieldsRes
  }, [fields, values])

  const hasVehicleInformation1 = useMemo(
    () => fields.some((item) => item.tabLabel === DocusignFieldTabLabel.vehicleMake2),
    [fields],
  )
  const hasVehicleInformation2 = useMemo(
    () => fields.some((item) => item.tabLabel === DocusignFieldTabLabel.vehicleMake3),
    [fields],
  )

  const addVehicleInformation1 = useCallback(() => {
    setShowAdditionalVehicleInformation1(true)
  }, [])

  const addVehicleInformation2 = useCallback(() => {
    setShowAdditionalVehicleInformation2(true)
  }, [])

  const removeVehicleInformation1 = useCallback(() => {
    setShowAdditionalVehicleInformation1(false)

    setFieldValue(DocusignFieldTabLabel.vehicleMake2, '')
    setFieldValue(DocusignFieldTabLabel.vehicleModel2, '')
    setFieldValue(DocusignFieldTabLabel.vehicleYear2, '')
    setFieldValue(DocusignFieldTabLabel.vehicleVIN2, '')
    setFieldValue(DocusignFieldTabLabel.vehicleLicensePlate2, '')
  }, [setFieldValue])

  const removeVehicleInformation2 = useCallback(() => {
    setShowAdditionalVehicleInformation2(false)

    setFieldValue(DocusignFieldTabLabel.vehicleMake3, '')
    setFieldValue(DocusignFieldTabLabel.vehicleModel3, '')
    setFieldValue(DocusignFieldTabLabel.vehicleYear3, '')
    setFieldValue(DocusignFieldTabLabel.vehicleVIN3, '')
    setFieldValue(DocusignFieldTabLabel.vehicleLicensePlate3, '')
  }, [setFieldValue])

  return (
    <div>
      {orderedFields.map((item) => {
        const isVehicleInformation =
          item.sectionKey === DocusignSectionTitle.primaryVehicleInformation
        const isVehicleInformation1 =
          item.sectionKey === DocusignSectionTitle.additionalVehicleInformation1
        const isVehicleInformation2 =
          item.sectionKey === DocusignSectionTitle.additionalVehicleInformation2

        return (isVehicleInformation1 && !showAdditionalVehicleInformation1) ||
          (isVehicleInformation2 && !showAdditionalVehicleInformation2) ? null : (
          <div css={tw`mb-6`} key={item.sectionKey}>
            <Typography variant="overline" color={colors.SHADES_GREY_400}>
              {docusignSectionsTitleMap[item.sectionKey]}
            </Typography>
            <div css={tw`bg-white px-4 pt-2 mt-2 rounded-2xl`}>
              {item.fields.map((field) => (
                <DocusignFieldsFormModalInput key={field.tabLabel} field={field} />
              ))}
              {isVehicleInformation &&
                hasVehicleInformation1 &&
                !showAdditionalVehicleInformation1 && (
                  <Button
                    leftIcon={<CirclePlusMajor width={20} fill={colors.PRIMARY_PURPLE_500} />}
                    color="light"
                    className={tw`w-full mb-4`}
                    onClick={addVehicleInformation1}
                  >
                    Add additional vehicle
                  </Button>
                )}
              {isVehicleInformation1 &&
                hasVehicleInformation2 &&
                !showAdditionalVehicleInformation2 && (
                  <Button
                    leftIcon={<CirclePlusMajor width={20} fill={colors.PRIMARY_PURPLE_500} />}
                    color="light"
                    className={tw`w-full mb-4`}
                    onClick={addVehicleInformation2}
                  >
                    Add additional vehicle
                  </Button>
                )}
              {isVehicleInformation1 &&
                hasVehicleInformation2 &&
                !showAdditionalVehicleInformation2 && (
                  <Button
                    leftIcon={<CircleMinusMajor width={20} />}
                    color="grey"
                    className={tw`w-full mb-4`}
                    onClick={removeVehicleInformation1}
                  >
                    Remove this vehicle
                  </Button>
                )}
              {isVehicleInformation2 && (
                <Button
                  leftIcon={<CircleMinusMajor width={20} />}
                  color="grey"
                  className={tw`w-full mb-4`}
                  onClick={removeVehicleInformation2}
                >
                  Remove this vehicle
                </Button>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
