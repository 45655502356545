import { call, put, takeEvery } from 'redux-saga/effects'
import {
  FetchDocusignSigningEnvelopeOptions,
  OnboardingDocusignCompleteSigning,
  OnboardingDocusignDeclineSigning,
  OnboardingDocusignStartSigning,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { DataCollectionSource } from 'src/constants/shared'
import {
  DocusignSigningEnvelopeOptionsResponse,
  DocusignStartSigningResponse,
} from 'src/interfaces/docusign'
import { AsyncAction } from 'src/utils/reduxUtils'
import { ApiService, callOnboardingSecureApi } from '../api/callApi'

interface OnboardingDocusignStartSigningRequest {
  signingFields?: Array<{
    tabLabel: string
    value: string
  }>
  stepId: string
  embedded: boolean
}

function* onOnboardingDocusignStartSigning(
  action: AsyncAction<OnboardingDocusignStartSigningRequest, DocusignStartSigningResponse>,
) {
  try {
    const { signingFields, stepId, embedded } = action.payload || {}

    const body: Omit<OnboardingDocusignStartSigningRequest, 'stepId' | 'embedded'> = {}

    if (signingFields?.length) {
      body.signingFields = signingFields
    }

    const { payload }: { payload: DocusignStartSigningResponse } = yield call(
      callOnboardingSecureApi,
      Endpoints.DocusignStartSigning,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        body,
        query: {
          flow: DataCollectionSource.Onboarding,
          stepId,
          embedded: embedded.toString(),
        },
      },
    )

    yield put(OnboardingDocusignStartSigning.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch docusign signing url'

    yield put(OnboardingDocusignStartSigning.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface OnboardingDocusignCompleteSigningRequest {
  stepId: string
}

function* onOnboardingDocusignCompleteSigning(
  action: AsyncAction<OnboardingDocusignCompleteSigningRequest>,
) {
  try {
    yield call(callOnboardingSecureApi, Endpoints.DocusignCompleteSigning, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      query: {
        flow: DataCollectionSource.Onboarding,
        stepId: action.payload.stepId,
      },
    })

    yield put(OnboardingDocusignCompleteSigning.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to complete signing'

    yield put(OnboardingDocusignCompleteSigning.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface OnboardingDocusignDeclineSigningRequest {
  stepId: string
}

function* onOnboardingDocusignDeclineSigning(
  action: AsyncAction<OnboardingDocusignDeclineSigningRequest>,
) {
  try {
    yield call(callOnboardingSecureApi, Endpoints.DocusignDeclineSigning, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      query: {
        flow: DataCollectionSource.Onboarding,
        stepId: action.payload.stepId,
      },
    })

    yield put(OnboardingDocusignDeclineSigning.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to decline signing'

    yield put(OnboardingDocusignDeclineSigning.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface FetchSigningEnvelopeOptionsRequest {
  stepId: string
}

function* onFetchSigningEnvelopeOptions(
  action: AsyncAction<FetchSigningEnvelopeOptionsRequest, DocusignSigningEnvelopeOptionsResponse>,
) {
  try {
    const { stepId } = action.payload || {}

    const { payload }: { payload: DocusignSigningEnvelopeOptionsResponse } = yield call(
      callOnboardingSecureApi,
      Endpoints.FetchDocusignSigningEnvelopeOptions,
      {
        method: 'GET',
        apiService: ApiService.Paraworks,
        query: {
          stepId,
        },
      },
    )

    yield put(FetchDocusignSigningEnvelopeOptions.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage =
      err?.payload?.message || 'Failed to fetch docusign signing envelope options'

    yield put(FetchDocusignSigningEnvelopeOptions.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingDocusignSaga() {
  yield takeEvery(OnboardingDocusignStartSigning.type.REQUEST, onOnboardingDocusignStartSigning)
  yield takeEvery(
    OnboardingDocusignCompleteSigning.type.REQUEST,
    onOnboardingDocusignCompleteSigning,
  )
  yield takeEvery(OnboardingDocusignDeclineSigning.type.REQUEST, onOnboardingDocusignDeclineSigning)
  yield takeEvery(FetchDocusignSigningEnvelopeOptions.type.REQUEST, onFetchSigningEnvelopeOptions)
}
