import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { QuestionMarkMajor } from '@shopify/polaris-icons'
import { OnboardingSecureWrapper } from 'src/components/layout/onboarding/OnboardingSecureWrapper'
import { OnboardingUploadQuestionnaire } from 'src/constants/actionTypes'
import { OnboardingStepIndex } from 'src/components/onboarding/OnboardingStepIndex'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { CheckboxButton } from 'src/components/shared/CheckboxButton'
import { QuestionnaireOnboardingStep } from 'src/interfaces/onboarding'

export const QuestionnairePage = () => {
  const dispatch = useDispatch()

  const currentStep = useSelector(getCurrentOnboardingStep) as QuestionnaireOnboardingStep

  const [answers, setAnswers] = useState(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = async () => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        OnboardingUploadQuestionnaire.request({
          answers: currentStep?.metadata.map((item) => ({
            id: item.id,
            type: item.type,
            value: answers.get(item.id),
          })),
          stepId: currentStep.id,
        }),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeAnswer = useCallback((id: string, value: boolean) => {
    setAnswers((prev) => {
      const newMap = new Map(prev)

      newMap.set(id, value)

      return newMap
    })
  }, [])

  if (!currentStep) {
    return null
  }

  return (
    <OnboardingSecureWrapper>
      <div css={tw`h-full w-full flex flex-col justify-between`}>
        <div>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
          >
            <QuestionMarkMajor width={20} />
          </div>
          <OnboardingStepIndex stepId={currentStep.id} />
          <Typography css={tw`my-2`} variant="h2">
            Questionnaire
          </Typography>
          <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
            Let's see if you're a good fit.
          </Typography>
          <div css={tw`mt-6`}>
            {currentStep.metadata.map((item) =>
              item.type === 'boolean' ? (
                <div key={item.id} css={tw`bg-white rounded-2xl p-4 mb-4`}>
                  <Typography css={tw`mt-2 mb-4`} color={colors.SHADES_GREY_400}>
                    {item.title}
                  </Typography>
                  <div css={tw`flex h-[72px] gap-4`}>
                    <CheckboxButton
                      title="Yes"
                      selected={answers.has(item.id) && Boolean(answers.get(item.id))}
                      onClick={() => handleChangeAnswer(item.id, true)}
                    />
                    <CheckboxButton
                      title="No"
                      selected={answers.has(item.id) && !Boolean(answers.get(item.id))}
                      onClick={() => handleChangeAnswer(item.id, false)}
                    />
                  </div>
                </div>
              ) : null,
            )}
          </div>
        </div>
        <FormErrorMessage css={tw`mb-4`} error={error} />
        <LoadingButton
          css={tw`w-full mt-2`}
          variant="contained"
          loading={isLoading}
          disabled={answers.size < currentStep.metadata.length}
          onClick={handleSubmit}
        >
          <span>Submit and continue</span>
        </LoadingButton>
      </div>
    </OnboardingSecureWrapper>
  )
}
