import { memo } from 'react'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { useSelector } from 'react-redux'
import { getOnboardingStepIndex, getOnboardingStepsCount } from 'src/selectors/onboarding'

interface Props {
  stepId: string | undefined
}

const OnboardingStepIndexComponent = ({ stepId }: Props) => {
  const onboardingStepIndex = useSelector(getOnboardingStepIndex(stepId))
  const onboardingStepsCount = useSelector(getOnboardingStepsCount)

  return (
    <Typography variant="overline" color={colors.PRIMARY_PURPLE_400}>
      {`STEP ${onboardingStepIndex} OF ${onboardingStepsCount}`}
    </Typography>
  )
}

export const OnboardingStepIndex = memo(OnboardingStepIndexComponent)
