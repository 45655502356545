import { call, put, takeEvery } from 'redux-saga/effects'
import {
  RecollectionDocusignCompleteSigning,
  RecollectionDocusignDeclineSigning,
  RecollectionDocusignStartSigning,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { DataCollectionSource } from 'src/constants/shared'
import { DocusignStartSigningResponse } from 'src/interfaces/docusign'
import { AsyncAction } from 'src/utils/reduxUtils'
import { ApiService, callRecollectionSecureApi } from '../api/callApi'

interface RecollectionDocusignStartSigningRequest {
  signingFields?: Array<{
    tabLabel: string
    value: string
  }>
  stepId: string
  embedded: boolean
}

function* onRecollectionDocusignStartSigning(
  action: AsyncAction<RecollectionDocusignStartSigningRequest, DocusignStartSigningResponse>,
) {
  try {
    const { signingFields, stepId, embedded } = action.payload || {}

    const body: Omit<RecollectionDocusignStartSigningRequest, 'stepId' | 'embedded'> = {}

    if (signingFields?.length) {
      body.signingFields = signingFields
    }

    const { payload }: { payload: DocusignStartSigningResponse } = yield call(
      callRecollectionSecureApi,
      Endpoints.DocusignStartSigning,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        body,
        query: {
          flow: DataCollectionSource.Recollection,
          stepId,
          embedded: embedded.toString(),
        },
      },
    )

    yield put(RecollectionDocusignStartSigning.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch docusign signing url'

    yield put(RecollectionDocusignStartSigning.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface RecollectionDocusignCompleteSigningRequest {
  stepId: string
}

function* onRecollectionDocusignCompleteSigning(
  action: AsyncAction<RecollectionDocusignCompleteSigningRequest>,
) {
  try {
    yield call(callRecollectionSecureApi, Endpoints.DocusignCompleteSigning, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      query: {
        flow: DataCollectionSource.Recollection,
        stepId: action.payload.stepId,
      },
    })

    yield put(RecollectionDocusignCompleteSigning.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to complete signing'

    yield put(RecollectionDocusignCompleteSigning.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface RecollectionDocusignDeclineSigningRequest {
  stepId: string
}

function* onRecollectionDocusignDeclineSigning(
  action: AsyncAction<RecollectionDocusignDeclineSigningRequest>,
) {
  try {
    yield call(callRecollectionSecureApi, Endpoints.DocusignDeclineSigning, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      query: {
        flow: DataCollectionSource.Recollection,
        stepId: action.payload.stepId,
      },
    })

    yield put(RecollectionDocusignDeclineSigning.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to decline signing'

    yield put(RecollectionDocusignDeclineSigning.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* recollectionDocusignSaga() {
  yield takeEvery(RecollectionDocusignStartSigning.type.REQUEST, onRecollectionDocusignStartSigning)
  yield takeEvery(
    RecollectionDocusignCompleteSigning.type.REQUEST,
    onRecollectionDocusignCompleteSigning,
  )
  yield takeEvery(
    RecollectionDocusignDeclineSigning.type.REQUEST,
    onRecollectionDocusignDeclineSigning,
  )
}
