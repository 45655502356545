import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material'
import { RiskMinor } from '@shopify/polaris-icons'
import React, { useMemo } from 'react'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props {
  name?: string
  label?: string
  value?: any
  error?: boolean
  helperText?: string | false
  onChange(e: React.ChangeEvent<any>): void
}

export const BasicCheckbox = ({ name, label, value, error, helperText, onChange }: Props) => {
  const radioColors = useMemo(() => {
    if (error) {
      return {
        bgColor: colors.COLORS_RED_25,
        borderColor: colors.COLORS_RED_200,
        color: colors.COLORS_RED_500,
        textColor: colors.COLORS_RED_500,
      }
    }

    return value
      ? {
          bgColor: colors.PRIMARY_PURPLE_25,
          borderColor: colors.PRIMARY_PURPLE_200,
          color: colors.PRIMARY_PURPLE_500,
          textColor: colors.PRIMARY_PURPLE_500,
        }
      : {
          bgColor: colors.GRAY_WHITE,
          borderColor: colors.SHADES_GREY_200,
          color: colors.SHADES_GREY_400,
          textColor: colors.SHADES_GREY_500,
        }
  }, [value, error])

  return (
    <FormControl error={error}>
      <FormControlLabel
        css={[
          tw`w-full rounded-lg border border-solid ml-0 py-3 px-2`,
          {
            borderColor: radioColors.borderColor,
            backgroundColor: radioColors.bgColor,
          },
        ]}
        control={
          <Checkbox
            name={name}
            checked={!!value}
            value={true}
            css={[
              {
                color: radioColors.color,
              },
            ]}
            onChange={onChange}
          />
        }
        label={
          <Typography color={radioColors.textColor} variant="body1">
            {label}
          </Typography>
        }
      />
      {!!error && !!helperText && (
        <div css={tw`flex items-center gap-1 mt-2`}>
          <RiskMinor width={20} fill={colors.COLORS_RED_500} />
          <Typography variant="body2" color={colors.COLORS_RED_500}>
            {helperText}
          </Typography>
        </div>
      )}
    </FormControl>
  )
}
