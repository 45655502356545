import { Checkbox, Typography } from '@mui/material'
import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { colors } from 'src/theme'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { OnboardingUploadBGC, RecollectionUploadBGC } from 'src/constants/actionTypes'
import BGCIcon from 'src/assets/icons/bgc.svg'
import { BGCOnboardingStep } from 'src/interfaces/onboarding'
import { OnboardingStepIndex } from 'src/components/onboarding/OnboardingStepIndex'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { Formik } from 'formik'
import { BGCManualUploadFormSchema } from './BGCManualUploadForm.validator'
import { FormikFileInput } from '../shared/form/formik/FormikFileInput'
import { BGCManualUploadFormValues } from './BGCManualUploadForm.interface'
import { FormErrorMessage } from '../shared/form/FormErrorMessage'
import { BGCProceedWithYardstik } from './BGCProceedWithYardstik'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'

interface Props {
  flow: 'onboarding' | 'recollect'
  isYardstikEnabled?: boolean
}

const initialValues: BGCManualUploadFormValues = {
  bgcFile: undefined,
  mvrFile: undefined,
}

const BGCManualUploadComponent = ({ flow, isYardstikEnabled }: Props) => {
  const dispatch = useDispatch()

  const currentStep = useSelector(
    flow === 'recollect' ? getCurrentRecollectionStep : getCurrentOnboardingStep,
  ) as BGCOnboardingStep

  const [showMVRInput, setShowMVRInput] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: BGCManualUploadFormValues) => {
    setIsLoading(true)
    setError(null)

    const requestBody = {
      stepId: currentStep.id,
      files: [
        {
          file: values.bgcFile,
          name: 'background_check',
        },
      ],
    }

    if (values.mvrFile) {
      requestBody.files.push({
        file: values.mvrFile,
        name: 'mvr',
      })
    }

    try {
      if (flow === 'onboarding') {
        await createAsyncAction(dispatch, OnboardingUploadBGC.request(requestBody))
      } else if (flow === 'recollect') {
        await createAsyncAction(dispatch, RecollectionUploadBGC.request(requestBody))
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={BGCManualUploadFormSchema}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <img src={BGCIcon} />
            </div>
            {flow === 'onboarding' && <OnboardingStepIndex stepId={currentStep.id} />}
            <Typography css={tw`mb-6 mt-2`} variant="h2">
              {isYardstikEnabled
                ? 'Upload required documents'
                : 'Background Check & Motor Vehicle Record'}
            </Typography>
            <Typography color={colors.GRAY_DARK_COOL}>
              {isYardstikEnabled
                ? 'Once you submit the documents, you will automatically advance to the next step.'
                : 'Upload required documents. Once you submit the documents, you will automatically advance to the next step.'}
            </Typography>
            {isYardstikEnabled && <BGCProceedWithYardstik flow={flow} />}
            <div css={tw`mt-6 bg-white p-4 rounded-2xl mb-8`}>
              <Typography css={tw`mb-2`} fontWeight={600}>
                Upload the file/s that contain a Background Check and Motor Vehicle Record
              </Typography>
              <Typography color={colors.GRAY_DARK_COOL} css={tw`mb-4`}>
                Checks must be less than 60 days old.
              </Typography>
              <FormikFileInput name="bgcFile" />
              <div css={tw`bg-[#F6F6F6] p-3 mt-6 rounded-xl flex flex-row items-center`}>
                <Checkbox
                  checked={showMVRInput}
                  onChange={(_, checked) => {
                    setShowMVRInput(checked)
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography css={tw`ml-2`}>
                  I want to upload Motor Vehicle Record as a separate file.
                </Typography>
              </div>
            </div>
            {showMVRInput && (
              <div css={tw`mt-6 bg-white p-4 rounded-2xl mb-8`}>
                <Typography css={tw`mb-2`} fontWeight={600}>
                  Upload Motor Vehicle Record
                </Typography>
                <Typography color={colors.GRAY_DARK_COOL} css={tw`mb-4`}>
                  Checks must be less than 60 days old.
                </Typography>
                <FormikFileInput name="mvrFile" />
              </div>
            )}
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full`}
            variant="contained"
            onClick={() => {
              handleSubmit()
            }}
            loading={isLoading}
          >
            <span>Submit documents</span>
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}

export const BGCManualUpload = memo(BGCManualUploadComponent)
