import { createActions } from 'src/utils/reduxUtils'

// recollection
export const RecollectionFetchDriver = createActions('RecollectionFetchDriver')
export const RecollectionSetDriver = createActions('RecollectionSetDriver')
export const RecollectionSetAuthToken = createActions('RecollectionSetAuthToken')
export const RecollectionLogout = createActions('RecollectionLogout')
export const RecollectionSignInPhone = createActions('RecollectionSignInPhone')
export const RecollectionSignInEnterCode = createActions('RecollectionSignInEnterCode')
export const RecollectionSignInResendCode = createActions('RecollectionSignInResendCode')
export const RecollectionUploadBasicInfo = createActions('RecollectionUploadBasicInfo')
export const RecollectionUploadVehicleInsurance = createActions(
  'RecollectionUploadVehicleInsurance',
)
export const RecollectionUploadInsuranceDeclaration = createActions(
  'RecollectionUploadInsuranceDeclaration',
)
export const RecollectionUploadVehicleRegistration = createActions(
  'RecollectionUploadVehicleRegistration',
)
export const RecollectionUploadVaccination = createActions('RecollectionUploadVaccination')
export const RecollectionUploadPaymentMethod = createActions('RecollectionUploadPaymentMethod')
export const RecollectionUploadInsuranceBeneficiary = createActions(
  'RecollectionUploadInsuranceBeneficiary',
)
export const RecollectionUploadSignContract = createActions('RecollectionUploadSignContract')
export const RecollectionUploadTraining = createActions('RecollectionUploadTraining')
export const RecollectionUploadBGC = createActions('RecollectionUploadBGC')
export const RecollectionUploadMVR = createActions('RecollectionUploadMVR')
export const RecollectionUploadEIN = createActions('RecollectionUploadEIN')
export const RecollectionUploadForm = createActions('RecollectionUploadForm')
export const RecollectionFetchPersonaInquiry = createActions('RecollectionFetchPersonaInquiry')
export const RecollectionSetPersonaSessionToken = createActions(
  'RecollectionSetPersonaSessionToken',
)
export const RecollectionSetPersonaClientCompletedInquiry = createActions(
  'RecollectionSetPersonaClientCompletedInquiry',
)
export const RecollectionFetchYardstikReport = createActions('RecollectionFetchYardstikReport')
export const RecollectionDocusignStartSigning = createActions('RecollectionDocusignStartSigning')
export const RecollectionDocusignCompleteSigning = createActions(
  'RecollectionDocusignCompleteSigning',
)
export const RecollectionDocusignDeclineSigning = createActions(
  'RecollectionDocusignDeclineSigning',
)
export const RecollectionStitchStartFlow = createActions('RecollectionStitchStartFlow')
export const RecollectionStitchCompletePayment = createActions('RecollectionStitchCompletePayment')
export const RecollectionSetNewDocsUploaded = createActions('RecollectionSetNewDocsUploaded')

// onboarding
export const OnboardingFetchDriver = createActions('OnboardingFetchDriver')
export const OnboardingSetDriver = createActions('OnboardingSetDriver')
export const OnboardingSetAuthToken = createActions('OnboardingSetAuthToken')
export const OnboardingSignInPhone = createActions('OnboardingSignInPhone')
export const OnboardingSignUp = createActions('OnboardingSignUp')
export const OnboardingSignInEnterCode = createActions('OnboardingSignInEnterCode')
export const OnboardingSignInResendCode = createActions('OnboardingSignInResendCode')
export const OnboardingUploadQuestionnaire = createActions('OnboardingUploadQuestionnaire')
export const OnboardingUploadVehicleInsurance = createActions('OnboardingUploadVehicleInsurance')
export const OnboardingUploadInsuranceDeclaration = createActions(
  'OnboardingUploadInsuranceDeclaration',
)
export const OnboardingUploadVehicleRegistration = createActions(
  'OnboardingUploadVehicleRegistration',
)
export const OnboardingUploadVaccination = createActions('OnboardingUploadVaccination')
export const OnboardingUploadPaymentMethod = createActions('OnboardingUploadPaymentMethod')
export const OnboardingUploadInsuranceBeneficiary = createActions(
  'OnboardingUploadInsuranceBeneficiary',
)
export const OnboardingUploadSignContract = createActions('OnboardingUploadSignContract')
export const OnboardingUploadTraining = createActions('OnboardingUploadTraining')
export const OnboardingUploadBGC = createActions('OnboardingUploadBGC')
export const OnboardingUploadMVR = createActions('OnboardingUploadMVR')
export const OnboardingUploadEIN = createActions('OnboardingUploadEIN')
export const OnboardingUploadForm = createActions('OnboardingUploadForm')
export const OnboardingFetchPersonaInquiry = createActions('OnboardingFetchPersonaInquiry')
export const OnboardingSetPersonaSessionToken = createActions('OnboardingSetPersonaSessionToken')
export const OnboardingSetPersonaClientCompletedInquiry = createActions(
  'OnboardingSetPersonaClientCompletedInquiry',
)
export const OnboardingFetchYardstikReport = createActions('OnboardingFetchYardstikReport')
export const OnboardingDocusignStartSigning = createActions('OnboardingDocusignStartSigning')
export const OnboardingDocusignCompleteSigning = createActions('OnboardingDocusignCompleteSigning')
export const OnboardingDocusignDeclineSigning = createActions('OnboardingDocusignDeclineSigning')
export const OnboardingStitchStartFlow = createActions('OnboardingStitchStartFlow')
export const OnboardingStitchCompletePayment = createActions('OnboardingStitchCompletePayment')
export const OnboardingLogout = createActions('OnboardingLogout')

export const FetchDocusignSigningEnvelopeOptions = createActions(
  'FetchDocusignSigningEnvelopeOptions',
)

export const FetchPartnerPublicInfo = createActions('FetchPartnerPublicInfo')

// contractor portal
export const ContractorPortalFetchDriver = createActions('ContractorPortalFetchDriver')
export const ContractorPortalSetDriver = createActions('ContractorPortalSetDriver')
export const ContractorPortalSetAuthToken = createActions('ContractorPortalSetAuthToken')
export const ContractorPortalLogout = createActions('ContractorPortalLogout')
export const ContractorPortalSignInPhone = createActions('ContractorPortalSignInPhone')
export const ContractorPortalFetchPartners = createActions('ContractorPortalFetchPartners')
export const ContractorPortalFetchInvoices = createActions('ContractorPortalFetchInvoices')
export const ContractorPortalFetchInvoice = createActions('ContractorPortalFetchInvoice')
export const ContractorPortalFetchDocuments = createActions('ContractorPortalFetchDocuments')
export const ContractorPortalSignInEnterCode = createActions('ContractorPortalSignInEnterCode')
export const ContractorPortalSignInResendCode = createActions('ContractorPortalSignInResendCode')
export const ContractorPortalSwitchPartner = createActions('ContractorPortalSwitchPartner')
