import React, { useMemo } from 'react'
import { FormHelperText, Typography } from '@mui/material'
import { FormControl, MenuItem, Select } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { RiskMinor } from '@shopify/polaris-icons'

interface Props {
  name?: string
  css?: any
  label?: string
  placeholder?: string
  value?: string | number
  error?: boolean
  helperText?: string | false
  options: Array<BasicSelectOption>
  onChange(e: React.ChangeEvent<any>): void
  onBlur?: (e: React.FocusEvent<any>) => void
}

const DEFAULT_SELECT_VALUE = 'DEFAULT_SELECT_VALUE'

export interface BasicSelectOption {
  label: string
  value: string | number
}

export const BasicSelect = ({
  name,
  css,
  label,
  placeholder,
  value,
  error,
  helperText,
  options,
  onChange,
  onBlur,
}: Props) => {
  const handleChange = (event: any) => {
    onChange(event)
  }

  const handleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event)
    }
  }

  const _helperText = useMemo(() => {
    if (!helperText) {
      return null
    }

    if (error) {
      return (
        <FormHelperText css={tw`ml-0`} component="div">
          <div css={tw`flex items-center gap-1`}>
            <RiskMinor width={20} fill={colors.COLORS_RED_500} />
            <Typography variant="body2" color={colors.COLORS_RED_500} component="span">
              {helperText}
            </Typography>
          </div>
        </FormHelperText>
      )
    }

    return <FormHelperText>{helperText}</FormHelperText>
  }, [error, helperText])

  return (
    <div css={css}>
      <Typography css={tw`mb-1`} variant="h6" color={colors.GRAY_COOL_GRAY}>
        {label}
      </Typography>
      <FormControl fullWidth error={error}>
        <Select
          name={name}
          labelId="select-label"
          id="simple-select"
          value={value || DEFAULT_SELECT_VALUE}
          placeholder={placeholder}
          renderValue={(value) => {
            if (value === DEFAULT_SELECT_VALUE) {
              return <Typography color="rgba(0, 0, 0, 0.37)">{placeholder}</Typography>
            }

            return value
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {_helperText}
      </FormControl>
    </div>
  )
}
