import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { BlogMajor } from '@shopify/polaris-icons'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SignContractAgreementModal } from 'src/components/SignContractAgreementModal'
import { DocusignFieldsFormModal } from 'src/components/docusign-field-form-modal/DocusignFieldsFormModal'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'
import {
  FetchDocusignSigningEnvelopeOptions,
  RecollectionDocusignCompleteSigning,
  RecollectionDocusignDeclineSigning,
  RecollectionDocusignStartSigning,
  RecollectionFetchDriver,
} from 'src/constants/actionTypes'
import { RECOLLECTION_ROUTES } from 'src/constants/routes'
import { PartnerNameType, partnerAgreementContentMap } from 'src/constants/signContract'
import {
  DocusignSigningEnvelopeOptionsResponse,
  DocusignSigningField,
  DocusignStartSigningResponse,
} from 'src/interfaces/docusign'
import { getOnboardingDriver } from 'src/selectors/driver'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'
import { colors } from 'src/theme'
import { useQuery } from 'src/utils/hooks/useQuery'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { captureSentryError } from 'src/utils/sentry'
import { showToast } from 'src/utils/toast'
import tw from 'twin.macro'

const DOCUSIGN_CONTAINER_ID = 'docusign-container'

interface SigningField {
  tabLabel: string
  value: string
}

const SignContractPageComponent = () => {
  const dispatch = useDispatch()
  const { getQuery } = useQuery()
  const navigate = useNavigate()

  const driver = useSelector(getOnboardingDriver)
  const currentStep = useSelector(getCurrentRecollectionStep)

  const [docusignSigningData, setDocusignSigningData] =
    useState<DocusignStartSigningResponse | null>(null)
  const [signingEnvelopeOptions, setSigningEnvelopeOptions] =
    useState<DocusignSigningEnvelopeOptionsResponse | null>(null)
  const [isStartLoading, setIsStartLoading] = useState<boolean>(false)
  const [isSigningFallbackLoading, setIsSigningFallbackLoading] = useState<boolean>(false)
  const [isFieldsModalOpen, setIsFieldsModalOpen] = useState<boolean>(false)
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState<boolean>(false)
  const [modalFields, setModalFields] = useState<Array<DocusignSigningField>>([])
  const [signingIframeCrashed, setSigningIframeCrashed] = useState<boolean>(false)

  const signingIframeLoadCheckTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const signingIframeCorsCheckIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const signingIframeExistsCheckIntervalRef = useRef<NodeJS.Timeout | null>(null)

  const docusignReturnEvent = getQuery('event')

  useEffect(() => {
    if (!currentStep || !docusignReturnEvent) {
      return
    }

    if (docusignReturnEvent === 'signing_complete') {
      navigate(RECOLLECTION_ROUTES.DOCS_REUPLOAD_URL)

      handleCompleteSigning()
    }
  }, [currentStep, docusignReturnEvent])

  const agreementContent = useMemo(
    () =>
      driver?.partner?.name
        ? partnerAgreementContentMap[driver?.partner?.name as PartnerNameType]
        : undefined,
    [driver?.partner?.name],
  )

  const handleLoadDocusignSigningEnvelopeOptions = useCallback(async () => {
    try {
      const response: DocusignSigningEnvelopeOptionsResponse | undefined = await createAsyncAction(
        dispatch,
        FetchDocusignSigningEnvelopeOptions.request({
          stepId: currentStep?.id,
        }),
      )

      if (!response) {
        throw new Error('Signing envelope options are not available')
      }

      setSigningEnvelopeOptions(response)
    } catch (err) {
      // ignore this error
    }
  }, [currentStep])

  useEffect(() => {
    handleLoadDocusignSigningEnvelopeOptions()
  }, [currentStep])

  const handleStartSigning = useCallback(
    async (signingFields?: Array<SigningField>) => {
      setIsStartLoading(true)

      try {
        const signingResponse: DocusignStartSigningResponse | undefined = await createAsyncAction(
          dispatch,
          RecollectionDocusignStartSigning.request({
            signingFields,
            stepId: currentStep?.id,
            embedded: true,
          }),
        )

        if (!signingResponse) {
          throw new Error('Signing is not available')
        }

        if (signingResponse.retryFetchDriver) {
          await createAsyncAction(dispatch, RecollectionFetchDriver.request())

          return
        }

        if (signingResponse.signingFields?.length) {
          setModalFields(signingResponse.signingFields)
          setIsFieldsModalOpen(true)
          setIsStartLoading(false)

          return
        }

        setDocusignSigningData(signingResponse)
        handleLoadDocusign(signingResponse)
      } catch (err) {
        showToast('Failed to load signing page, please try again later', {
          variant: 'error',
        })
      } finally {
        setIsStartLoading(false)
      }
    },
    [currentStep],
  )

  const handleStartFallbackSigning = useCallback(async () => {
    setIsSigningFallbackLoading(true)

    try {
      const signingResponse: DocusignStartSigningResponse | undefined = await createAsyncAction(
        dispatch,
        RecollectionDocusignStartSigning.request({
          stepId: currentStep?.id,
          embedded: false,
        }),
      )

      if (!signingResponse) {
        throw new Error('Signing is not available')
      }

      if (signingResponse.retryFetchDriver) {
        await createAsyncAction(dispatch, RecollectionFetchDriver.request())

        return
      }

      if (signingResponse.signingData?.url) {
        window.open(signingResponse.signingData.url, '_blank')
      } else {
        throw new Error('Signing is not available')
      }
    } catch (err) {
      showToast('Failed to load signing page, please try again later', {
        variant: 'error',
      })
    } finally {
      setIsSigningFallbackLoading(false)
    }
  }, [currentStep])

  const handleCompleteSigning = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        RecollectionDocusignCompleteSigning.request({ stepId: currentStep?.id }),
      )
      await createAsyncAction(dispatch, RecollectionFetchDriver.request())
    } catch (err) {
      showToast('Failed to finish signing, please try again later', {
        variant: 'error',
      })
    }
  }, [currentStep])

  const handleDeclineSigning = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        RecollectionDocusignDeclineSigning.request({ stepId: currentStep?.id }),
      )
      await createAsyncAction(dispatch, RecollectionFetchDriver.request())
    } catch (err) {
      // ignore this error
    }
  }, [currentStep])

  const handleLoadDocusign = useCallback(async (payload: DocusignStartSigningResponse) => {
    if (!payload.signingData?.url) {
      return
    }

    try {
      const docusign = await window.DocuSign.loadDocuSign(
        process.env.REACT_APP_DOCUGIGN_INTEGRATION_KEY as string,
      )

      const signing = docusign.signing({
        url: payload.signingData.url,
        displayFormat: 'focused',
        style: {
          branding: {
            primaryButton: {
              backgroundColor: '#333',
              color: '#fff',
            },
          },
          signingNavigationButton: {
            finishText: 'Continue',
            position: 'bottom-center',
          },
        },
      })

      signing.on('ready', () => {
        setIsStartLoading(false)
      })

      signing.on('sessionEnd', async (event) => {
        if (event.sessionEndType === 'signing_complete') {
          handleCompleteSigning()
          return
        }

        if (event.sessionEndType === 'ttl_expired') {
          setDocusignSigningData(null)
          showToast('Signing link is expired, please start signing again', {
            variant: 'error',
          })

          return
        }

        if (event.sessionEndType === 'decline') {
          await handleDeclineSigning()
          setDocusignSigningData(null)
          showToast('Signing is declined, please start signing again or contact support', {
            variant: 'error',
          })

          return
        }
      })

      signing.mount(`#${DOCUSIGN_CONTAINER_ID}`)

      startSigningIframeListeners()
    } catch (err: any) {
      setIsStartLoading(false)
      captureSentryError(err, {
        message: '[handleLoadDocusign]',
      }) //
    }
  }, [])

  const startSigningIframeListeners = useCallback(() => {
    const iframe = document.getElementById('js-library-iframe')

    if (!iframe) {
      setSigningIframeCrashed(true)

      captureSentryError(new Error('Docusign iframe is not found'), {
        message: '[startSigningIframeListeners]',
      })

      return
    }

    let isIframeLoaded = false

    iframe.addEventListener('load', () => {
      isIframeLoaded = true
    })

    signingIframeLoadCheckTimeoutRef.current = setTimeout(() => {
      if (!isIframeLoaded) {
        setSigningIframeCrashed(true)

        captureSentryError(new Error('Docusign iframe is not loaded'), {
          message: '[startSigningIframeListeners]',
        })
      }
    }, 10000)

    iframe.addEventListener('error', () => {
      setSigningIframeCrashed(true)

      captureSentryError(new Error('Docusign iframe is crashed'), {
        message: '[startSigningIframeListeners]',
      })
    })

    signingIframeExistsCheckIntervalRef.current = setInterval(() => {
      if (!document.getElementById('js-library-iframe')) {
        setSigningIframeCrashed(true)

        captureSentryError(new Error('Docusign iframe is not found'), {
          message: '[startSigningIframeListeners]',
        })
      }
    }, 3000)
  }, [])

  useEffect(() => {
    if (!signingIframeCrashed) {
      return
    }

    if (signingIframeLoadCheckTimeoutRef.current) {
      clearTimeout(signingIframeLoadCheckTimeoutRef.current)
    }

    if (signingIframeCorsCheckIntervalRef.current) {
      clearInterval(signingIframeCorsCheckIntervalRef.current)
    }

    if (signingIframeExistsCheckIntervalRef.current) {
      clearInterval(signingIframeExistsCheckIntervalRef.current)
    }

    showToast('Failed to start signing, please try again', {
      variant: 'error',
    })
  }, [signingIframeCrashed])

  const handleCloseFieldsModal = useCallback(() => {
    setIsFieldsModalOpen(false)
  }, [])

  const handleSubmitFieldsModal = useCallback(
    async (signingFields?: Array<SigningField>) => {
      await handleStartSigning(signingFields)

      setIsFieldsModalOpen(false)
    },
    [handleStartSigning],
  )

  const handleOpenAgreementModal = useCallback(() => {
    setIsAgreementModalOpen(true)
  }, [])

  const handleCloseAgreementModal = useCallback(() => {
    setIsAgreementModalOpen(false)
  }, [])

  const handleStartSigningPress = useCallback(() => {
    if (agreementContent) {
      handleOpenAgreementModal()

      return
    }

    handleStartSigning()
  }, [agreementContent, handleStartSigning])

  const handleSubmitAgreement = useCallback(() => {
    handleCloseAgreementModal()
    handleStartSigning()
  }, [handleStartSigning])

  return (
    <RecollectionSecureWrapper>
      <div css={tw`h-full flex flex-col justify-between`}>
        <div>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-white mb-4`}
          >
            <BlogMajor width={20} />
          </div>
          <Typography css={tw`mb-6`} variant="h2">
            Sign the contract
          </Typography>
          <Typography color={colors.GRAY_DARK_COOL} css={tw`mb-12`}>
            Sign your contracts and relevant documents via Docusign. Tap the button below to begin.
            {signingEnvelopeOptions?.signingEnvelopeAvailable ? (
              <Typography color={colors.GRAY_DARK_COOL} css={tw`mt-4`}>
                If you are experiencing issues with the signing process, please try{' '}
                <strong>Sign the contract in a new tab</strong> instead.
              </Typography>
            ) : null}
          </Typography>
        </div>
        <div>
          {signingIframeCrashed ? (
            <LoadingButton
              css={tw`w-full`}
              variant="contained"
              loading={isSigningFallbackLoading}
              onClick={handleStartFallbackSigning}
            >
              <span>Sign the contract in a new tab</span>
            </LoadingButton>
          ) : (
            <LoadingButton
              css={tw`w-full`}
              variant="contained"
              loading={isStartLoading}
              onClick={handleStartSigningPress}
            >
              <span>Sign the contract</span>
            </LoadingButton>
          )}
          {signingEnvelopeOptions?.signingEnvelopeAvailable && !signingIframeCrashed ? (
            <LoadingButton
              css={tw`w-full mt-4`}
              variant="text"
              loading={isSigningFallbackLoading}
              onClick={handleStartFallbackSigning}
            >
              <span>Sign the contract in a new tab</span>
            </LoadingButton>
          ) : null}
        </div>
      </div>
      {!signingIframeCrashed ? (
        <div
          id={DOCUSIGN_CONTAINER_ID}
          css={[
            tw`fixed top-0 left-0 h-full w-full bg-[#FFFFFF] overflow-auto`,
            docusignSigningData ? undefined : tw`opacity-0 hidden`,
          ]}
        />
      ) : null}
      <DocusignFieldsFormModal
        isOpen={isFieldsModalOpen}
        fields={modalFields}
        onClose={handleCloseFieldsModal}
        onSubmit={handleSubmitFieldsModal}
      />
      <SignContractAgreementModal
        isOpen={isAgreementModalOpen}
        content={agreementContent}
        onClose={handleCloseAgreementModal}
        onSubmit={handleSubmitAgreement}
      />
    </RecollectionSecureWrapper>
  )
}

export const SignContractPage = memo(SignContractPageComponent)
