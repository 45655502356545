import { OnboardingStep, TrainingOnboardingStep } from 'src/interfaces/onboarding'
import { State } from '../reducers/types'
import {
  ONBOARDING_STEP_TIME_TO_COMPLETE,
  OnboardingStatus,
  OnboardingStepType,
} from 'src/constants/onboarding'
import {
  clientSortSteps,
  backendSortSteps,
  sortByDisabled,
  sortByCreated,
} from 'src/utils/onboarding'

export const getOnboardingSteps = (state: State): OnboardingStep[] => {
  const steps =
    state.onboardingDriver.onboardingDriver?.onboarding.collectDataSteps.filter(
      (item) => item.status !== OnboardingStatus.Archived,
    ) || []

  const sortedSteps = steps.every((item) => item.order === 0)
    ? clientSortSteps(steps)
    : backendSortSteps(steps)

  return sortByCreated(sortByDisabled(sortedSteps))
}

export const getCurrentOnboardingStep = (state: State): OnboardingStep | null => {
  const steps = getOnboardingSteps(state)

  return steps.find((item) => item.status === OnboardingStatus.Created) || null
}

export const getOnlyDelayedStepsLeft = (state: State): boolean => {
  const steps = getOnboardingSteps(state)

  return (
    steps.every(
      (item) =>
        item.status !== OnboardingStatus.Created ||
        (item.disabled === true && item.status === OnboardingStatus.Created),
    ) && steps.some((item) => item.status === OnboardingStatus.Created)
  )
}

export const getOnboardingStepsRemainingTimeToComplete = (state: State): number => {
  const steps = getOnboardingSteps(state)

  return steps.reduce(
    (res, item) =>
      (res +=
        (item.status === OnboardingStatus.Created && ONBOARDING_STEP_TIME_TO_COMPLETE[item.type]) ||
        0),
    0,
  )
}

export const getOnboardingStepIndex =
  (stepId: string | undefined) =>
  (state: State): number => {
    const steps = getOnboardingSteps(state)
    const index = steps.findIndex((item) => item.id === stepId)

    return index >= 0 ? index + 1 : 0
  }

export const getOnboardingStepsCount = (state: State): number =>
  (state.onboardingDriver.onboardingDriver?.onboarding.collectDataSteps || []).length

export const getIsOnboardingStarted = (state: State): boolean => {
  const steps = getOnboardingSteps(state)

  return steps.some((item) => item.status !== OnboardingStatus.Created)
}

export const getIsOnboardingFinished = (state: State): boolean => {
  const steps = getOnboardingSteps(state)

  return steps.every((item) => item.status !== OnboardingStatus.Created)
}

export const getIsOnboardingFailed = (state: State): boolean => {
  const steps = getOnboardingSteps(state)

  return steps.some((item) => item.status === OnboardingStatus.Failed)
}

const isTrainingStep = (step: OnboardingStep): step is TrainingOnboardingStep =>
  step.type === OnboardingStepType.Training

export const getOnboardingTrainingStep = (state: State): TrainingOnboardingStep | null => {
  const steps = getOnboardingSteps(state)

  return steps.find(isTrainingStep) || null
}

export const getStepByType =
  <T>(stepType: OnboardingStepType) =>
  (state: State): T | null => {
    const steps = getOnboardingSteps(state)

    return (steps.find((item) => item.type === stepType) as T) || null
  }
