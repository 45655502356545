import { Formik } from 'formik'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VaccinationFormValues } from './VaccinationForm.interface'
import { VaccinationSchema } from './VaccinationForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography, Checkbox } from '@mui/material'
import { colors } from 'src/theme'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
import { VaccineType, vaccineFormTypes } from 'src/constants/vaccintion'
import { SecureMajor } from '@shopify/polaris-icons'
import { OnboardingUploadVaccination } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { OnboardingStepIndex } from '../OnboardingStepIndex'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'

const initialValues: VaccinationFormValues = {
  date: '',
  brand: VaccineType.Comirnaty,
}

const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

export const VaccinationForm = () => {
  const dispatch = useDispatch()

  const currentStep = useSelector(getCurrentOnboardingStep)

  const formikRef = useRef<any>()

  const [notVaccinated, setNotVaccinated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: VaccinationFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        OnboardingUploadVaccination.request({
          ...values,
          isVaccinated: !notVaccinated,
          stepId: currentStep?.id,
        }),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeNoVaccinated = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotVaccinated(event.target.checked)
  }

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      validationSchema={notVaccinated ? undefined : VaccinationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <SecureMajor width={20} />
            </div>
            <OnboardingStepIndex stepId={currentStep?.id} />
            <Typography css={tw`my-2`} variant="h2">
              Vaccination information
            </Typography>
            <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
              Enter details about your most recent vaccination.
            </Typography>
            <Typography css={tw`mb-1 uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
              Enter vaccine details
            </Typography>
            <div css={tw`bg-white p-4 rounded-xl`}>
              <FormikTextField
                name="date"
                css={tw`w-full mb-4`}
                label="Date of most recent vaccination"
                mask={DATE_MASK}
                placeholder="Enter date"
              />
              <div css={tw`w-full`}>
                <FormikSelect
                  name="brand"
                  label="Vaccine brand you received"
                  placeholder="Select vaccination brand"
                  options={vaccineFormTypes}
                />
              </div>
            </div>
            <div css={tw`bg-white p-3 mt-6 rounded-xl flex flex-row items-center`}>
              <Checkbox
                checked={notVaccinated}
                onChange={handleChangeNoVaccinated}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography css={tw`ml-2`}>I'm not vaccinated</Typography>
            </div>
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full mt-6`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            <span>Submit and continue</span>
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}
