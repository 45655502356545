import { SvgIcon, Typography } from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsOnboardingDriverInitialized, getOnboardingDriver } from 'src/selectors/driver'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { colors } from 'src/theme'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  OnboardingStitchStartFlow,
  OnboardingStitchCompletePayment,
} from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import { OnboardingSecureWrapper } from 'src/components/layout/onboarding/OnboardingSecureWrapper'
import { OnboardingStepIndex } from 'src/components/onboarding/OnboardingStepIndex'
import { CancelMajor, FinancesMajor } from '@shopify/polaris-icons'
import qs from 'query-string'
import _ from 'lodash'
import { StitchIframeMessage } from 'src/constants/stitch'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'

const StitchPaymentPageComponent = () => {
  const dispatch = useDispatch()

  const isDriverInitialized = useSelector(getIsOnboardingDriverInitialized)
  const driver = useSelector(getOnboardingDriver)
  const currentStep = useSelector(getCurrentOnboardingStep)

  const [stitchToken, setStitchToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleStitchCancel = useCallback(() => {
    setStitchToken(null)
  }, [])

  const handleStitchComplete = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        OnboardingStitchCompletePayment.request({
          stepId: currentStep?.id,
        }),
      )
    } catch (err) {
      showToast('Failed to complete payment step, please try again later', {
        variant: 'error',
      })
    }

    setStitchToken(null)
  }, [currentStep])

  const handleStitchStart = useCallback(async () => {
    setIsLoading(true)

    try {
      const { token }: { token: string } = await createAsyncAction(
        dispatch,
        OnboardingStitchStartFlow.request({
          stepId: currentStep?.id,
        }),
      )

      if (token) {
        setStitchToken(token)
      } else {
        throw new Error('no token')
      }
    } catch (err) {
      showToast('Failed to load payment page, please try again later', {
        variant: 'error',
      })
    }

    setIsLoading(false)
  }, [currentStep?.id, handleStitchComplete])

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      // TODO: Once webhooks are ready, we should send a request to the server to make stitch payment step as in_progress
      if (
        e.data === StitchIframeMessage.AccountAdded ||
        e.data === StitchIframeMessage.ManualReview
      ) {
        handleStitchComplete()
      }
    }

    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
    }
  }, [])

  const iframeSource = useMemo(() => {
    if (!stitchToken) {
      return null
    }

    const query = {
      auth: stitchToken,
      email: driver?.email,
      phone: driver?.phone,
      first_name: driver?.firstName,
      last_name: driver?.lastName,
    }

    const queryString = qs.stringify(_.omitBy(query, _.isNil))

    return `${process.env.REACT_APP_STITCH_URL}/?${queryString}`
  }, [stitchToken, driver])

  if (!isDriverInitialized) {
    return <div />
  }

  return (
    <OnboardingSecureWrapper>
      <div css={tw`h-full flex w-full flex-col justify-between`}>
        <div>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
          >
            <FinancesMajor width={20} />
          </div>
          <OnboardingStepIndex stepId={currentStep?.id} />
          <Typography css={tw`mb-6 mt-2`} variant="h2">
            Get paid!
          </Typography>
          <Typography color={colors.GRAY_DARK_COOL} css={tw`mb-4`}>
            To set up your payments we'll use our industry leading partner, Stitch. Tap the button
            below to begin.
          </Typography>
          <InfoContainer>
            Once you've completed your payment setup, we'll automatically advance you to the next
            step.
          </InfoContainer>
        </div>
        {iframeSource && (
          <div css={tw`fixed w-full h-full top-0 left-0 z-20`}>
            <iframe css={tw`w-full h-full border-0`} src={iframeSource} />
            <SvgIcon
              css={tw`absolute right-5 top-5 w-5 h-5 z-20 cursor-pointer`}
              onClick={handleStitchCancel}
            >
              <CancelMajor color={colors.GRAY_DARK_COOL} />
            </SvgIcon>
          </div>
        )}
        <LoadingButton
          loading={isLoading}
          css={tw`w-full h-[56px]`}
          variant="contained"
          onClick={handleStitchStart}
        >
          <span>Set up payment</span>
        </LoadingButton>
      </div>
    </OnboardingSecureWrapper>
  )
}

export const StitchPaymentPage = memo(StitchPaymentPageComponent)
