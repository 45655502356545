import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { QuestionMarkMajor } from '@shopify/polaris-icons'
import { OnboardingStepIndex } from 'src/components/onboarding/OnboardingStepIndex'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { FormStep } from 'src/interfaces/onboarding'
import { CollectDataFormItem } from 'src/components/collect-data-form/CollectDataFormItem'
import { Formik } from 'formik'
import { getValidationSchema } from './CollectDataForm.validator'
import { CollectDataFormItemType } from 'src/constants/collectData'
import { CollectDataFormInputItem, UploadFormResponse } from 'src/interfaces/collectData'
import { getFormUploadValue } from './CollectDataForm.utils'
import { OnboardingUploadForm, RecollectionUploadForm } from 'src/constants/actionTypes'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'
import { FailedAttemptModal } from './FailedAttemptModal'

interface Props {
  flow: 'onboarding' | 'recollect'
}

export const CollectDataForm = ({ flow }: Props) => {
  const dispatch = useDispatch()

  const currentStep = useSelector(
    flow === 'recollect' ? getCurrentRecollectionStep : getCurrentOnboardingStep,
  ) as FormStep

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFailedAttemptModalOpen, setIsFailedAttemptModalOpen] = useState<boolean>(false)
  const [attemptsLeft, setAttemptsLeft] = useState<number>(0)
  const [error, setError] = useState<string | null>(null)

  const formVilidatableItems = currentStep.metadata.formItems.filter(
    (item) =>
      item.type !== CollectDataFormItemType.TextBlock &&
      item.type !== CollectDataFormItemType.Video,
  ) as CollectDataFormInputItem[]

  const openFailedAttemptModal = useCallback(() => {
    setIsFailedAttemptModalOpen(true)
  }, [])

  const closeFailedAttemptModal = useCallback(() => {
    setIsFailedAttemptModalOpen(false)
  }, [])

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true)
    setError(null)

    try {
      const data = formVilidatableItems
        .map((item) => ({
          id: item.id,
          type: item.type,
          title: item.title,
          value: getFormUploadValue(item, values[item.id]),
        }))
        .filter((item) => !!item.value)

      const requestBody = {
        data: data.filter((item) => item.type !== CollectDataFormItemType.File),
        files: data.filter((item) => item.type === CollectDataFormItemType.File),
        stepId: currentStep.id,
      }

      const response: UploadFormResponse = await createAsyncAction(
        dispatch,
        flow === 'onboarding'
          ? OnboardingUploadForm.request(requestBody)
          : RecollectionUploadForm.request(requestBody),
      )

      if (
        response?.code === 'attempt_failed' &&
        response?.collectDataStep?.maxAttempts + 1 - response?.collectDataStep?.failedAttempts > 0
      ) {
        setAttemptsLeft(
          response?.collectDataStep?.maxAttempts + 1 - response?.collectDataStep?.failedAttempts,
        )
        openFailedAttemptModal()
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  if (!currentStep) {
    return null
  }

  return (
    <Formik
      initialValues={formVilidatableItems.reduce((acc: any, item) => {
        acc[item.id] = item.type === CollectDataFormItemType.Checkbox ? [] : ''

        return acc
      }, {})}
      onSubmit={handleFormSubmit}
      validationSchema={getValidationSchema(formVilidatableItems)}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <QuestionMarkMajor width={20} />
            </div>
            {flow === 'onboarding' && <OnboardingStepIndex stepId={currentStep.id} />}
            <Typography css={tw`my-2`} variant="h2">
              {currentStep.metadata.title || 'Questionnaire'}
            </Typography>
            <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
              {currentStep.metadata.description || "Let's see if you're a good fit."}
            </Typography>
            <div css={tw`mt-6 bg-white p-4 rounded-2xl mb-8`}>
              {currentStep.metadata.formItems.map((item) => (
                <CollectDataFormItem key={item.id} collectDataFormItem={item} />
              ))}
            </div>
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full mt-2`}
            variant="contained"
            loading={isLoading}
            onClick={() => {
              handleSubmit()
            }}
          >
            <span>Submit and continue</span>
          </LoadingButton>
          <FailedAttemptModal
            attemptsLeft={attemptsLeft}
            isOpen={isFailedAttemptModalOpen}
            onClose={closeFailedAttemptModal}
          />
        </div>
      )}
    </Formik>
  )
}
