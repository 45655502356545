import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React from 'react'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { RiskMinor } from '@shopify/polaris-icons'

interface Props {
  name?: string
  label?: string
  value?: string | number
  error?: boolean
  helperText?: string | false
  options: Array<BasicRadioOption>
  onChange(e: React.ChangeEvent<any>): void
}

export interface BasicRadioOption {
  label: string
  value: string | number | boolean
}

export const BasicRadioGroup = ({
  name,
  label,
  value,
  error,
  helperText,
  options,
  onChange,
}: Props) => {
  const getColors = (option: BasicRadioOption) => {
    if (error) {
      return {
        bgColor: colors.COLORS_RED_25,
        borderColor: colors.COLORS_RED_200,
        color: colors.COLORS_RED_500,
      }
    }

    return value === option.value
      ? {
          bgColor: colors.PRIMARY_PURPLE_25,
          borderColor: colors.PRIMARY_PURPLE_200,
          color: colors.PRIMARY_PURPLE_500,
        }
      : {
          bgColor: colors.GRAY_WHITE,
          borderColor: colors.SHADES_GREY_200,
          color: colors.SHADES_GREY_400,
        }
  }

  return (
    <FormControl css={tw`w-full`} error={error}>
      <FormLabel id={name}>
        <Typography css={tw`mb-2`} variant="h6" color={colors.GRAY_COOL_GRAY}>
          {label}
        </Typography>
      </FormLabel>
      <RadioGroup aria-labelledby={name} name={name} value={value} onChange={onChange}>
        {options.map((option, idx) => {
          const colors = getColors(option)

          return (
            <FormControlLabel
              key={option.label}
              value={option.value}
              css={[
                tw`w-full rounded-lg border border-solid ml-0 p-0.5`,
                idx !== options.length - 1 && tw`mb-2`,
                {
                  borderColor: colors.borderColor,
                  backgroundColor: colors.bgColor,
                },
              ]}
              control={
                <Radio
                  css={[
                    {
                      color: colors.color,
                    },
                  ]}
                />
              }
              label={
                <Typography css={tw`w-full`} color={colors.color}>
                  {option.label}
                </Typography>
              }
            />
          )
        })}
      </RadioGroup>
      {!!error && !!helperText && (
        <FormHelperText css={tw`ml-0 mt-2`} component="div">
          <div css={tw`flex items-center gap-1`}>
            <RiskMinor width={20} fill={colors.COLORS_RED_500} />
            <Typography variant="body2" color={colors.COLORS_RED_500} component="span">
              {helperText}
            </Typography>
          </div>
        </FormHelperText>
      )}
    </FormControl>
  )
}
