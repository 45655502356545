import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Checkbox,
  FormGroup,
  Typography,
} from '@mui/material'
import tw, { css } from 'twin.macro'
import { RiskMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'

interface Props {
  name?: string
  label?: string
  value?: (string | number | boolean)[]
  error?: boolean
  helperText?: string | false
  options: Array<BasicRadioOption>
  onChange(e: React.ChangeEvent<any>): void
}

export interface BasicRadioOption {
  label: string
  value: string | number | boolean
}

export const BasicCheckboxSelect = ({
  name,
  label,
  value,
  error,
  helperText,
  options,
  onChange,
}: Props) => {
  const getBorderColorStyle = (option: BasicRadioOption) => {
    if (error) {
      return css`
        border-color: ${colors.COLORS_RED_500};
      `
    }

    if (value?.includes(option.value)) {
      return css`
        border-color: #7f00ff;
      `
    }

    return css`
      border-color: #847e96;
    `
  }

  return (
    <FormControl css={tw`w-full`} error={error}>
      <FormLabel id={name}>{label}</FormLabel>
      <FormGroup aria-labelledby={name} onChange={onChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value + option.label}
            value={option.value}
            css={[
              tw`w-full rounded-lg border border-solid mb-4 ml-0 p-0.5`,
              getBorderColorStyle(option),
            ]}
            control={<Checkbox />}
            label={
              <Typography css={tw`w-full`} color={colors.SHADES_GREY_400}>
                {option.label}
              </Typography>
            }
          />
        ))}
      </FormGroup>
      {!!error && !!helperText && (
        <FormHelperText css={tw`ml-0`} component="div">
          <div css={tw`flex items-center gap-1`}>
            <RiskMinor width={20} fill={colors.COLORS_RED_500} />
            <Typography variant="body2" color={colors.COLORS_RED_500} component="span">
              {helperText}
            </Typography>
          </div>
        </FormHelperText>
      )}
    </FormControl>
  )
}
