import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { DocusignRadioOption, DocusignSigningField } from 'src/interfaces/docusign'
import {
  DocusignFieldTabLabel,
  DocusignFieldNames,
  DocusignFieldPlaceholders,
  DocusignFieldSelectOptions,
  DocusignTaxClassificationLabelMap,
  TaxClassificationType,
} from 'src/constants/docusign'
import { FormikSelect } from '../shared/form/formik/FormikSelect'
import { FormikRadioGroup } from '../shared/form/formik/FormikRadioGroup'
import { FormikCheckbox } from '../shared/form/formik/FormikCheckbox'
import { useFormikContext } from 'formik'
import { DocusignFieldsFormModalValues } from './DocusignFieldsFormModal.interface'
import { ChangeEvent, useCallback } from 'react'
import { Typography } from '@mui/material'
import React from 'react'
import { colors } from 'src/theme'

const masks = {
  [DocusignFieldTabLabel.phoneNumber]: undefined,
  [DocusignFieldTabLabel.phoneNumberHome]: undefined,
  [DocusignFieldTabLabel.email]: undefined,
  [DocusignFieldTabLabel.fullName]: undefined,
  [DocusignFieldTabLabel.address]: undefined,
  [DocusignFieldTabLabel.city]: undefined,
  [DocusignFieldTabLabel.state]: undefined,
  [DocusignFieldTabLabel.zipcode]: [/\d/, /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.cityStateZipcode]: undefined,
  [DocusignFieldTabLabel.ssn]: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.tin]: ['9', /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.fein]: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.dob]: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.beneficiary]: undefined,
  [DocusignFieldTabLabel.beneficiaryRelationship]: undefined,
  [DocusignFieldTabLabel.insuranceCarrier]: undefined,
  [DocusignFieldTabLabel.otherInsurancePlan]: undefined,
  [DocusignFieldTabLabel.eSignatureAck]: undefined,
  [DocusignFieldTabLabel.businessName]: undefined,
  [DocusignFieldTabLabel.ein]: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.vehicleYear]: [/\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.vehicleMake]: undefined,
  [DocusignFieldTabLabel.vehicleModel]: undefined,
  [DocusignFieldTabLabel.vehicleVIN]: undefined,
  [DocusignFieldTabLabel.vehicleLicensePlate]: undefined,
  [DocusignFieldTabLabel.vehicleYear2]: [/\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.vehicleMake2]: undefined,
  [DocusignFieldTabLabel.vehicleModel2]: undefined,
  [DocusignFieldTabLabel.vehicleVIN2]: undefined,
  [DocusignFieldTabLabel.vehicleLicensePlate2]: undefined,
  [DocusignFieldTabLabel.vehicleYear3]: [/\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.vehicleMake3]: undefined,
  [DocusignFieldTabLabel.vehicleModel3]: undefined,
  [DocusignFieldTabLabel.vehicleVIN3]: undefined,
  [DocusignFieldTabLabel.vehicleLicensePlate3]: undefined,
  [DocusignFieldTabLabel.taxClassificationType]: undefined,
  [DocusignFieldTabLabel.llcType]: undefined,
  [DocusignFieldTabLabel.exemptPayeeCode]: undefined,
  [DocusignFieldTabLabel.exemptionFatcaCode]: undefined,
  [DocusignFieldTabLabel.mmsOregonAddress]: undefined,
  [DocusignFieldTabLabel.driverLicenseNumber]: undefined,
  [DocusignFieldTabLabel.otherForeignPartners]: undefined,
  [DocusignFieldTabLabel.otherTaxClassification]: undefined,
}

interface Props {
  field: DocusignSigningField
}

const selectFields = [DocusignFieldTabLabel.state, DocusignFieldTabLabel.llcType]
const radioLabelMaps: any = {
  [DocusignFieldTabLabel.taxClassificationType]: DocusignTaxClassificationLabelMap,
}

export const DocusignFieldsFormModalInput = ({ field }: Props) => {
  const { handleChange, setFieldValue } = useFormikContext<DocusignFieldsFormModalValues>()

  const handleRadioChange = useCallback(
    (name: string) => (event: ChangeEvent) => {
      handleChange(event)

      if (name === DocusignFieldTabLabel.taxClassificationType) {
        setFieldValue(DocusignFieldTabLabel.llcType, '')
      }
    },
    [],
  )

  if (selectFields.includes(field.tabLabel) && DocusignFieldSelectOptions[field.tabLabel]) {
    return (
      <div css={tw`w-full pb-4 pt-2`}>
        <FormikSelect
          name={field.tabLabel}
          label={`${DocusignFieldNames[field.tabLabel]}${field.required ? '*' : ''}`}
          placeholder={DocusignFieldPlaceholders[field.tabLabel]}
          options={DocusignFieldSelectOptions[field.tabLabel]}
        />
      </div>
    )
  }

  if (field.tabType === 'radiogroup') {
    const labelMap = radioLabelMaps[field.tabLabel]
    let radioOptions = field.radios

    if (field.tabLabel === DocusignFieldTabLabel.taxClassificationType) {
      radioOptions = [
        radioOptions.find(
          (radio) => radio.value === TaxClassificationType.Individual,
        ) as DocusignRadioOption,
        radioOptions.find(
          (radio) => radio.value === TaxClassificationType.CCorp,
        ) as DocusignRadioOption,
        radioOptions.find(
          (radio) => radio.value === TaxClassificationType.SCorp,
        ) as DocusignRadioOption,
        radioOptions.find(
          (radio) => radio.value === TaxClassificationType.Partnership,
        ) as DocusignRadioOption,
        radioOptions.find(
          (radio) => radio.value === TaxClassificationType.Trust,
        ) as DocusignRadioOption,
        radioOptions.find(
          (radio) => radio.value === TaxClassificationType.Llc,
        ) as DocusignRadioOption,
        radioOptions.find(
          (radio) => radio.value === TaxClassificationType.Other,
        ) as DocusignRadioOption,
      ]
    }

    return (
      <div css={tw`w-full pb-4 pt-2`}>
        <FormikRadioGroup
          name={field.tabLabel}
          label={`${DocusignFieldNames[field.tabLabel]}${field.required ? '*' : ''}`}
          options={radioOptions.map((radio) => ({
            label: labelMap ? labelMap[radio.value] : radio.value,
            value: radio.value,
          }))}
          onChange={handleRadioChange(field.tabLabel)}
        />
      </div>
    )
  }

  if (field.tabType === 'checkbox') {
    return (
      <div css={tw`pb-4 pt-2`}>
        <FormikCheckbox
          css={tw`w-full`}
          name={field.tabLabel}
          label={DocusignFieldNames[field.tabLabel]}
        />
      </div>
    )
  }

  return (
    <>
      {field.tabLabel === DocusignFieldTabLabel.otherTaxClassification ? (
        <div css={tw`mb-4 mt-2`}>
          <Typography variant="body1" color={colors.GRAY_COOL_GRAY} fontWeight={500}>
            Go to{' '}
            <a href="https://www.irs.gov/FormW9" target="_blank">
              www.irs.gov/FormW9
            </a>{' '}
            for instructions
          </Typography>
        </div>
      ) : null}
      <div css={tw`w-full pb-4 pt-2`}>
        <FormikTextField
          name={field.tabLabel}
          css={tw`w-full`}
          label={`${DocusignFieldNames[field.tabLabel]}${field.required ? '*' : ''}`}
          mask={masks[field.tabLabel]}
          placeholder={DocusignFieldPlaceholders[field.tabLabel]}
        />
      </div>
    </>
  )
}
